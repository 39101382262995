import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import AgentIntakeForm from "./components/AgentIntakeForm/AgentIntakeForm";
import AgentIntakeFormGoogle from "./components/AgentIntakeFormGoogle/AgentIntakeFormGoogle";
import firebase from "./config/firebase.js";
import { isAdmin } from "./utils/userSession";

import './App.css';
//
const privateAdminRoute = (component, pathname) => (
  {component}
);
//
//   if (isAdmin()) {
//     return ( component );
//   }
//   return (
//       <Redirect to={{
//           pathname: '/login',
//           state: { from: pathname },
//         }}
//       />
//     )
//
// }

function requireAuth(nextState, replace, next) {
  console.log('isAdmin = '+isAdmin);
  if (!isAdmin) {
    replace({
      pathname: "/login",
      state: {nextPathname: nextState.location.pathname}
    });
  }
  next();
}

function App(props) {
  return (
    <React.StrictMode>
        <Switch>
          <Route path='/partner-portal/answer-net' component={AgentIntakeForm} />
          <Route path='/partner-portal/test' component={AgentIntakeFormGoogle} />
          <Route path='/company-intake' render={(props) => (
            <AgentIntakeForm companyForm={true} />
            )} />
          <Route path='*' component={() => {
              // console.log(props);
              //
              //
              // var starCountRef = firebase.database().ref('accounting');
              // starCountRef.on('value', (snapshot) => {
              //   const data = snapshot.val();
              //   console.log(data);
              // });
               window.location.href = 'https://ionsolarpros.com';
               return null;
          }}/>
        </Switch>
    </React.StrictMode>
  );
}

export default withRouter(App);
