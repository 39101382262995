import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import firebase, { adminDatabase } from "../../config/firebase.js";
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CIPHER_SECRET_KEY, electricBillOptions, states } from "../../config/config.js";
import CryptoJS from 'crypto-js';
import ScaleLoader from "react-spinners/ScaleLoader";
import "./AgentIntakeForm.min.css";
import { DatePicker, TimePicker, DateTimePicker, Spinner } from 'react-rainbow-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { usePlacesWidget } from "react-google-autocomplete";

import _ from "lodash";

library.add(faQuoteLeft);

const ECs = [
  {id: 'ieUgEhxY0dNt82OATxWDrgOhWzo1', name: 'Erik Williams'},
  {id: 'X1mcULLbR5Y80gALDtOLN9Bgda73', name: 'Jason Calabrese'},
  {id: 'LufWwQ1eX0c6n9X9TecDmnqCfD53', name: 'John Margiotta'},
  {id: 'zMktfpI8IeTygZR5889CghyV05h1', name: 'Matt Parlato'},
  {id: 'VjniA2GypWPpe4oxiL2QpZmwIQ52', name: 'Michael Servidio'},
  {id: '9mTI26wPehgl5ItuJrXhXNrQu8c2', name: 'Tom Labbe'},
  {id: 'xUMCfo8rs5P3kAI1xHqQO7AzuoK2', name: 'Walter Carmona'},
  {id: 'PuD6KwbnJXO74QuADWMrHAYXgzt2', name: 'ISP Company Account'},
  {id: 'n6WGWUXIC2XnARgqYljTSNXcVDu2', name: 'Chris Hayes'},
];

const callTypes = { sales: 'sales', service: 'service', other: 'other' }

function AgentIntakeForm(props) {
  const { register, handleSubmit, errors, reset, watch, setError, setValue } = useForm(); // initialize the hook
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [isDev, setIsDev] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [isHomeowner, setIsHomeowner] = useState(false);
  const [howTheyHeard, setHowTheyHeard] = useState(null);
  const [gStreet, setGStreet] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentTime, setAppointmentTime] = useState('');
  const [appointmentTimeZone, setAppointmentTimeZone] = useState('EST');
  const [appointmentConsultant, setAppointmentConsultant] = useState('TBD');
  const [appointmentConsultantID, setAppointmentConsultantID] = useState('');
  const [nextECID, setNextECID] = useState('');
  const [doesNotWantAppointment, setDoesNotWantAppointment] = useState(false);
  const [busy, setBusy] = useState(false);
  const { companyForm } = props;
  const callType = watch('call_type');
  const { ref, autocompleteRef } = usePlacesWidget({
      apiKey:'AIzaSyA2fsOQN2kIPG0OfyC_uYX7zO96vDYW7YQ',
      onPlaceSelected: (place) => {
        var number = null, street = null, state = 'CT', city = null, zip = null, lat = null, lng = null, place_id = null;
        if (_.isObject(place)) {
          if (_.isArray(place.address_components)) {
            place.address_components.forEach((ac, i) => {
              if (_.isObject(ac)) {
                if (_.isArray(ac.types)) {
                  ac.types.forEach((ac_t, i) => {
                    if (ac_t === 'administrative_area_level_1') {
                      state = ac.short_name;
                    } else if (ac_t === 'postal_code') {
                      zip = ac.short_name;
                    } else if (ac_t === 'locality') {
                      city = ac.short_name;
                    } else if (ac_t === 'route') {
                      street = ac.long_name;
                    } else if (ac_t === 'street_number') {
                      number = ac.short_name;
                    }
                  });

                }
              }
            });
            street = ((number || '') + ' ' + (street || '')).trim();

          }
          if (_.isString(place.place_id) && place.place_id !== '') {
            place_id = place.place_id;
          }
          if (_.isObject(place.geometry) && _.isObject(place.geometry.location)) {
            lat = place.geometry.location.lat();
            lng = place.geometry.location.lng();
          }

        }
        if (isDev === true) {
          console.log('street = '+street);
        }
        setGStreet(street);
        setValue('street',street);
        setValue('city',city);
        setValue('state',state);
        setValue('zip',zip);
        setValue('place_id',place_id);
        setValue('lat',lat);
        setValue('lng',lng);
        try {
          ref.current.value = street;
          setTimeout(() => {
            setValue('street',street);
            ref.current.value = street;
          },50);
        } catch (e) {
          console.log(e.message);
        }
      },
      location: {lat: 41.981972, lng: -88.006410},
      options: {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        // bounds: {south: 37.105596, west:-91.653871,north: 42.486296, east:-87.303285},
        // origin: {lat: 41.981972, lng: -88.006410}
        origin: {lat: 41.667856, lng: -88.257527},
        bounds: {south: 40.970688, west:-73.709292, north: 42.030891, east:-71.768446}
      }
    });
  const history = useHistory();

  const FORM_RESPONSE_SHOW_TIME = 8; // how long the form success message shows for

  useEffect(() => {
    if (props.isDev !== true) {
      getEC();
    }
  }, []);

  useEffect(() => {
    setIsDev(props.isDev === true);
    if (props.isDev === true) {
      setAppointmentConsultant('Chris Hayes');
      setAppointmentConsultantID('n6WGWUXIC2XnARgqYljTSNXcVDu2');
    }
  }, [props.isDev]);

  const getEC = () => {
    if (isDev === true) {
      setAppointmentConsultant('Chris Hayes');
      setAppointmentConsultantID('n6WGWUXIC2XnARgqYljTSNXcVDu2');
      return;
    }
    var firebaseRef = adminDatabase.child('ec-rotation').child('next');
    firebaseRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        if (_.isObject(snapshot.val())) {
          let currentEC = snapshot.val();
          if (_.isString(currentEC.name) && currentEC.name !== '' && _.isString(currentEC.u_id) && currentEC.u_id !== '') {
            setAppointmentConsultant(currentEC.name);
            setAppointmentConsultantID(currentEC.u_id);
            setNextECID(currentEC.u_id);
          }
        }
      } else {
        if (isDev === true) {
          console.log('error retrieving snapshot');
        }
      }
    });

  }

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
    if (String(cleaned).length > 10) {
      if (String(cleaned)[0] === '1') {
        return formatPhoneNumber(String(cleaned).substring(1,11));
      }
      return formatPhoneNumber(String(cleaned).substring(0,10));
    }
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  const onSubmitIntake = async (data) => {
    setBusy(true);
    let formData = Object.assign({},
      {
        is_company_lead: true,
        activated: false,
        send_create_account: false,
        source: "quote",
        type: 'SA',
      },
      _.omit(data, ['ec-display','ec_name']));

    if (appointmentDate && appointmentTime) {
      var dateString = appointmentDate.toLocaleString('en-US', { timeZone: 'America/New_York' });
      var month = ('0'+dateString.split('/')[0]).slice(-2);
      var day = ('0'+dateString.split('/')[1]).slice(-2);
      var year = dateString.split('/')[2].split(',')[0];
      var ISO_string = year+'-'+month+'-'+day+'T'+('0'+appointmentTime).slice(-5)+':00.000-04:00';
      var adt = new Date(ISO_string);
      if (_.isDate(adt)) {
        formData.appointment_date_time = adt.getTime();
      }
    }
    if (appointmentDate) {
      formData.appointment_date = getFormattedDate(appointmentDate);
    }
    if (appointmentTime) {
      var timeString = appointmentTime;
      if (appointmentTime.includes(':')) {
        var timeComponents = appointmentTime.split(':');
        var h = parseInt(timeComponents[0]);
        var m = timeComponents[1];
        var a = 'am';
        if (h > 11) {
          a = 'pm';
        }
        if (h > 12) {
          h -= 12;
        }
        if (h === 0) {
          h += 12;
        }
        timeString = h + ':' + m + a;
        if (isNaN(m) || parseInt(m) === 0) {
          timeString = h + a;
        }
      }
      formData.appointment_time = timeString;
    }
    // if (!(formData.heard && _.isString(formData.heard) && formData.heard !== '')) {
    //   delete formData.heard;
    // }
    var potentiallyBlankFields = ['lat','lng','place_id','heard','notes'];
    potentiallyBlankFields.forEach((field, i) => {
      if (_.isNil(formData[field]) || formData[field] === '') {
        delete formData[field];
      }
    });

    if (_.isString(gStreet) && gStreet !== '') {
      formData.street = gStreet.trim();
    }
    if (nextECID !== formData.parent_id) {
      formData.shouldRotate = false;
    }

    if (isDev === true) formData.parent_id = 'n6WGWUXIC2XnARgqYljTSNXcVDu2';

    var createCompanyLeadResponse;

    if (callType === callTypes.service) {
      createCompanyLeadResponse = await createServiceRequest(formData);
    } else if (callType === callTypes.other || !isHomeowner || !isResidential) {
      createCompanyLeadResponse = await createOtherRequest(formData);
    } else {
      createCompanyLeadResponse = await createCompanyLead(formData);
    }

    setBusy(false);
    if (createCompanyLeadResponse && _.isObject(createCompanyLeadResponse)) {
      if (createCompanyLeadResponse.success === false || createCompanyLeadResponse.success === false) {
        if (createCompanyLeadResponse.error && createCompanyLeadResponse.error.code) {
          switch (createCompanyLeadResponse.error.code) {
            case 'auth/email-already-exists':
            case 'auth/email-already-in-use':
              setResponse({
                message: 'That email address is already in use!',
                type: 'error',
              });
              break;

            case 'auth/invalid-email':
              setResponse({
                message: 'That email address is invalid.',
                type: 'error',
              });
              break;

            case 'auth/weak-password':
              setResponse({
                message: 'The provided password is weak.',
                type: 'error',
              });
              break;

            default:
              setResponse({
                message: 'Somthing went wrong, please try again.',
                type: 'error',
              });
              break;
          }
        } else {
          setResponse({
            message: 'Somthing went wrong, please try again.',
            type: 'error',
          });
        }
      } else {

        setResponse({
          message: 'We have submitted the request. We will be in touch with the caller shortly! This page will refresh automatically in 5 seconds to reload the form.',
          type: 'success',
        });
        reset();
        setLoading(false);
        setAppointmentDate(null);
        setAppointmentTime('');
        setAppointmentTimeZone('EST');
        setAppointmentConsultant('TBD');
        setAppointmentConsultantID('');
        setDoesNotWantAppointment(false);
        setTimeout(() => { setResponse(null);  getEC(); }, 5 * 1000);
      }
    }
  };

  const createCompanyLead = async (formData) => {
    if (isDev === true) {
      console.log('******** createCompanyLeadCallable *******');
      console.log(formData);
    }
    var createCompanyLeadCallable = firebase
          .functions()
          .httpsCallable('createCompanyLeadCallable');
    var createCompanyLeadCallableResponse = await createCompanyLeadCallable(formData);
    if (isDev === true) {
      console.log('******** createCompanyLeadCallableResponse *******');
    }
    if (createCompanyLeadCallableResponse && _.isObject(createCompanyLeadCallableResponse) && createCompanyLeadCallableResponse.data) {
      if (isDev === true) {
        console.log(createCompanyLeadCallableResponse.data);
      }
      return createCompanyLeadCallableResponse.data;
    }
    return null;
  }

  const createServiceRequest = async (formData) => {
    if (isDev === true) {
      console.log('******** createServiceRequest *******');
      console.log(formData);
    }
    var createServiceRequestCallable = firebase
          .functions()
          .httpsCallable('createServiceRequestCallable');
    var createServiceRequestCallableResponse = await createServiceRequestCallable(formData);
    if (isDev === true) {
      console.log('******** createServiceRequestCallableResponse *******');
    }
    if (createServiceRequestCallableResponse && _.isObject(createServiceRequestCallableResponse) && createServiceRequestCallableResponse.data) {
      if (isDev === true) {
        console.log(createServiceRequestCallableResponse.data);
      }
      return createServiceRequestCallableResponse.data;
    }
    return null;
  }

  const createOtherRequest = async (formData) => {
    if (isDev === true) {
      console.log('******** createOtherRequest *******');
      console.log(formData);
    }

    var createMiscRequestCallable = firebase
          .functions()
          .httpsCallable('createMiscRequestCallable');
    var createMiscRequestCallableResponse = await createMiscRequestCallable(formData);
    if (isDev === true) {
      console.log('******** createMiscRequestCallableResponse *******');
    }
    if (createMiscRequestCallableResponse && _.isObject(createMiscRequestCallableResponse) && createMiscRequestCallableResponse.data) {
      if (isDev === true) {
        console.log(createMiscRequestCallableResponse.data);
      }
      return createMiscRequestCallableResponse.data;
    }
    return null;
  }

  const codeText = () => {
    switch (howTheyHeard) {
      case 'mailer':
      case 'online':
        return 'Some of our marketing materials have a code on it. Do you have a code? It\'s ok if not.';
      case 'referral':
        return 'Did the person referring you give you a code? It\'s ok if not.';
      case 'word-of-mouth':
        return 'Did the person who told you about us give you a code? It\'s ok if not.';
      default:
        return 'Do you have a referral code? <br/>(Sometimes there are deals based on a code. It\'s ok if you don\'t have one.)';
    }
  }

  const roundTimeQuarterHour = (time) => {
    if (!time.includes(':')) {
      return '12:00';
    }
    var minutes = parseInt(time.split(':')[1]);
    var hours = parseInt(time.split(':')[0]);
    let roundedMinutes = (Math.round(minutes/15) * 15) % 60;
    var m = ('0' + String(roundedMinutes)).slice(-2);
    var h = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;
    h = ('0' + h).slice(-2);
    return h+':'+m;
  }

  const tomorrowDate = () => {
    var today = new Date();
    return new Date(today.getTime() + (24 * 60 * 60 * 1000));
  }

  const nextMonthDate = () => {
    var nextMonth = new Date();
    return new Date(nextMonth.getTime() + (30 * 24 * 60 * 60 * 1000));
  }

  const submitButtonText = () => {
    switch (callType) {
      case callTypes.service:
        return 'Submit Service Request';
      case callTypes.other:
        return 'Submit Request';
      case callTypes.service:
      default:
        return 'Submit Sales / Quote Request';

    }
  }

  const renderCallTypeChooser = () => (
    <>
    { companyForm !== true && (
    <div style={{marginBottom: '-10px', marginTop: '50px', textAlign: 'center',}}>Choose call type based on their previous answers. If not clear ask:<br/> "Are you calling in to start a new solar project? Or is this about an existing solar project?"</div>
    )}
    <CallTypeButtonWrapper>
      <CallTypeButton className={(callType === callTypes.sales || callType === null ? 'selected' : '')}>
        <input ref={register} type="radio" id={callTypes.sales} name="call_type" value={callTypes.sales} defaultChecked />
        <label htmlFor={callTypes.sales}>Sales / Quote</label>
      </CallTypeButton>
      <CallTypeButton className={(callType === callTypes.service ? 'selected' : '')}>
        <input ref={register} type="radio" id={callTypes.service} name="call_type" value={callTypes.service} />
        <label htmlFor={callTypes.service}>Service</label>
      </CallTypeButton>
      { companyForm !== true && (
      <CallTypeButton className={(callType === callTypes.other ? 'selected' : '')}>
        <input ref={register} type="radio" id={callTypes.other} name="call_type" value={callTypes.other} />
        <label htmlFor={callTypes.other}>Other</label>
      </CallTypeButton>
      )}
    </CallTypeButtonWrapper>
    </>
  )


  return (
    <>
    { busy && (
      <>
        <BusyOverlay />
        <Spinner style={{position: "fixed"}} size="large" type="arc" variant="brand" />
      </>
    )}
    <InviteIntakeForm style={(isDev ? {backgroundColor: '#fb9510'} : {})}>
      <form autoComplete="new-password" onSubmit={handleSubmit(onSubmitIntake)} >
        <img width="98px" height="100px" style={{margin: '0px auto'}} src="/assets/isp.png" alt="logo" />
        { companyForm !== true ? (
          <>
            <h1 style={{fontSize: 50, marginTop: 0, marginBottom: 10}}>Ion Solar Pros</h1>
            <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>Thank you for visiting Ion Solar Pros. How can I help you today?</ScriptText>
            <ScriptText className="customer"><FontAwesomeIcon icon="quote-left"/> <span>Customer:</span>Let them speak</ScriptText>
            { renderCallTypeChooser() }

            <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>Great. *(May I know how you heard about us? If they do not mention how after the introduction) *</ScriptText>
            <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>I just need to gather some quick information from you. (Capture customer information: Name, Email, Phone #, Address)</ScriptText>
            <Separator />
          </>
        ) : (
          <>
          { renderCallTypeChooser() }
          </>
        )}
        <div className="form-field-name">
          <label htmlFor="name">Name (First and Last):</label>
          <input id="name" name="name" autoComplete="new-password" placeholder="Caller's name" type="text" ref={register} />
        </div>
        <div className="form-field-email">
          <label htmlFor="email">Email:</label>
          <input id="email" name="email" autoComplete="new-password" type="email" placeholder="email@email.com" ref={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  })} />
                {errors.email && (<Error>Valid email is required.</Error>)}
        </div>
        <div className="form-field-phone">
          <label htmlFor="phone">Phone:</label>
          <input id="phone" name="phone" autoComplete="new-password" type="tel" placeholder="000-000-0000" ref={register({ required: true })} onChange={(phone_event) => {
              var x = phone_event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
              phone_event.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            }
          }/>
            {errors.phone && (<Error>Valid phone is required.</Error>)}
        </div>
        <Separator />
        <div className="form-field-street">
          <label htmlFor="street">Street (Address):</label>
          <input id="street" name="street" autoComplete="new-password" type="text" placeholder="123 Main" ref={ref} onChange={(event) => {
            setGStreet(event.target.value);
          } } />
        </div>
        <div className="city-state-zip-wrapper">
          <>
          <div className="form-field-city">
            <label htmlFor="city">City:</label>
            <input id="city" name="city" autoComplete="new-password" type="text" placeholder="City" ref={register({ required: true })} />
            {errors.city && (<Error>Valid city is required.</Error>)}
          </div>
          <div className="form-field-state">
            <label htmlFor="state">State:</label>

              <select id="state" defaultValue="CT" name="state" style={{backgroundColor: '#f8f8f8', border: 'none', padding: 14, width: '100%'}} ref={register}>
                { states.map(state => (
                  <option key={state.abbr} value={state.abbr}>{state.full}</option>
                ))};
              </select>
          </div>
          <div className="form-field-zip">
            <label htmlFor="zip">Zip:</label>
            <input id="zip" name="zip" autoComplete="new-password" type="text" placeholder="Zip" ref={register} />
          </div>
          </>
        </div>
        <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 0, marginTop: 20}}>
          <input type="checkbox" id="residential" name="residential" value={isResidential} ref={register} style={{flex:0}} onChange={e => { setIsResidential(e.target.checked); if (!e.target.checked) {setAppointmentTime(null); setAppointmentDate(null);}}}/>
          <label htmlFor="residential" style={{flex:1}}> Is this a residential address?</label>
        </div>
        <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 20, marginTop: 10}}>
          <input type="checkbox" id="homeowner" name="homeowner" value={isHomeowner} ref={register} style={{flex:0}} onChange={e => { setIsHomeowner(e.target.checked); if (!e.target.checked) {setAppointmentTime(null); setAppointmentDate(null);}}}/>
          <label htmlFor="homeowner" style={{flex:1}}> Are you the homeowner?</label>
        </div>
        <p style={{opacity: 0.5, fontSize: 12, fontStyle: 'italic', marginTop: -10}}>Please check the boxes above if the answer was yes, leave unchecked if no</p>

        <Separator />
        <div className="form-field-heard">
          <label htmlFor="heard">How did you hear about us:</label>
          <select id="heard" name="heard" style={{backgroundColor: '#f8f8f8', border: 'none', padding: 14}} ref={register} onChange={(event) => {setHowTheyHeard(event.target.value)}}>
            <option key="default" value="">Choose one</option>
            <option key="referral" value="referral">Referral (add notes if possible)</option>
            <option key="mailer" value="mailer">Mailer</option>
            <option key="online" value="online">Online</option>
            <option key="word-of-mouth" value="word-of-mouth">Word Of Mouth</option>
            <option key="unknown" value="">Customer doesn't know</option>
            ))}
          </select>
        </div>
        { companyForm !== true && (
        <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>{ codeText() }</ScriptText>
        )}
        <div className="form-field-code">
          <label htmlFor="heard">Referral code (optional, if customer has one):</label>
          <input style={{maxWidth: '140px'}} id="referral_code" name="referral_code" autoComplete="new-password" type="text" placeholder="Code (i.e. ABCDE)" ref={register} onChange={(code_event) => {
            code_event.target.value = (code_event.target.value || '').toUpperCase();
          }} />
        </div>
        <Separator />
        <div className="form-field-notes">
          <label htmlFor="ec">Notes:</label>
          <textarea style={{padding: 12, fontFamily:'Arial', fontSize:14, border: 'none', background: '#f8f8f8', borderRadius: '8px'}} id="notes" name="notes" autoComplete="new-password" type="textarea" placeholder="Enter any notes from the call (ie. who referred the caller?)" rows="5" ref={register} />
        </div>
        { companyForm !== true && (callType === callTypes.sales || callType === null) && isHomeowner && isResidential && (
          <>

        <Separator />
        <ScriptNote>ALWAYS ATTEMPT TO SCHEDULE AN IN-HOME APPOINTMENT WITH ONE OF OUR ENERGY CONSULTANTS. USE THE FOLLOWING SCRIPT</ScriptNote>
        <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>Great. The first step is to have one of our energy consultants come out to the home; we have morning, afternoon, or evening appointments available. Is there a better day or time that works for you?</ScriptText>
        <ScriptText className="customer"><FontAwesomeIcon icon="quote-left"/> <span>Customer:</span>Let caller speak</ScriptText>
        </>
        )}
        { ((callType === callTypes.sales || callType === null) && isHomeowner && isResidential) && (
          <>
        <Separator />
        <div className="form-field-appointment">
          <label htmlFor="appointment">Appointment (select best available date and time for customer):</label>
          <div className="form-field-appointment-picker-wrapper">
            <DatePicker
              value={appointmentDate}
              minDate={tomorrowDate()}
              onChange={value => {setAppointmentDate(value);}}
            />
            <TimePicker
                id="time-picker-1"
                value={appointmentTime}
                onChange={value => { setAppointmentTime(roundTimeQuarterHour(value));}}
                style={{}}
                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                onFocus={() => { if (!appointmentTime) { setAppointmentTime('12:00');} }}
            />
          { false && (
          <select id="time-zone" name="time-zone" ref={register} style={{flex: 0, padding: '11px 12px', margin: '5px 0', border: 'none', background: '#f8f8f8'}}>
            <option key="eastern" value="ET">EST</option>
            <option key="central" value="CT">CST</option>
            <option key="mountain" value="MT">MST</option>
            <option key="pacific" value="PT">PST</option>
          </select> ) }
          </div>
          <ScriptNote>Monday-Friday: 8a-7p</ScriptNote>
          <ScriptNote>Saturday: 9a-6p</ScriptNote>
          <ScriptNote>Sunday: 10a-5p</ScriptNote>
          <div style={{display: 'flex',flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 20, marginTop: 20}}>
            <input type="checkbox" id="no-appointment" name="no-appointment" value={doesNotWantAppointment} ref={register} style={{flex:0}} onChange={e => { setDoesNotWantAppointment(e.target.checked); if (e.target.checked) {setAppointmentTime(null); setAppointmentDate(null);}}}/>
            <label htmlFor="no-appointment" style={{flex:1}}> Caller does not want a scheduled appointment</label>
          </div>
        </div>
        <Separator />
        { doesNotWantAppointment ? (
          <>
        <ScriptNote>*If the caller is unwilling to schedule an appointment: *</ScriptNote>
        <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>Great. I will pass this information along to one of our energy consultants to contact you by the end of the day.</ScriptText>
        </>
        ) : (
          <>
          { _.isDate(appointmentDate) && _.isString(appointmentTime) && appointmentTime !== '' ? (
            <ScriptText><FontAwesomeIcon icon="quote-left"/> <span>Call Center:</span>Great. Confirming your appointment on {getFormattedDate(appointmentDate)} at {appointmentTime}. It looks like your energy consultant will be {appointmentConsultant}</ScriptText>
          ) : (
          <ScriptNote>If they are ready to schedule, choose the date and time that is best for the caller. After confirming with the caller, read back the day for the appointment with the date and time with the caller.</ScriptNote>
          )}
          </>
        )}
        <Separator />
        { companyForm !== true ? (
        <div className={`form-field-consultant ${(_.isDate(appointmentDate) && _.isString(appointmentTime) && appointmentTime !== '') && 'hidden'}`}>
          <label htmlFor="ec">Energy Consultant:</label>
          <input id="ec-display" name="ec-display" autoComplete="new-password" type="text" disabled={true} placeholder="TBD" ref={register} value={appointmentConsultant} readOnly={true}/>
        </div>
        ) : (
        <select id="parent_id" name="parent_id" ref={register} style={{flex: 0, padding: '11px 12px', margin: '5px 0', border: 'none', background: '#f8f8f8'}} onChange={(e) => {
            var selectedEC = ECs.filter(EC => EC.id === e.target.value);
            if (selectedEC && selectedEC.length > 0) {
              setAppointmentConsultant(selectedEC[0].name);
              console.log(selectedEC[0]);
            }
            setAppointmentConsultantID(e.target.value);
        }}>
          { ECs.map(EC => {
            if (EC.id === appointmentConsultantID) {
              return (
                <option key={EC.id} value={EC.id} selected>{EC.name}</option>
              )
            }
            return (
              <option key={EC.id} value={EC.id}>{EC.name}</option>
            )
        })}
        </select>
      )}
      </>
    )}

        <div style={{display: 'none'}}>
          <input id="ec_name" name="ec_name" autoComplete="new-password" type="text" ref={register} value={appointmentConsultant} readOnly={true} />
          <input id="parent_id" name="parent_id" autoComplete="new-password" type="text" ref={register} value={appointmentConsultantID} readOnly={true} />
          <input id="intake" name="intake" autoComplete="new-password" ref={register} value={companyForm ? 'company-web-form' : 'answer-net'} readOnly={true} />
          <input id="lat" name="lat" autoComplete="new-password" ref={register} readOnly={true} />
          <input id="lng" name="lng" autoComplete="new-password" ref={register} readOnly={true} />
          <input id="place_id" name="place_id" autoComplete="new-password" ref={register} readOnly={true} />
        </div>
        <input type="submit" disabled={(busy)} value={submitButtonText()}/>
        { response !== null && _.isObject(response) && (
          <div className={'form-response form-response-'+response.type}>{response.message}</div>
        )}
        { errors !== null && _.isObject(errors) && Object.keys(errors).length > 0 && (
          <Error className={'form-response'}>Please fix form errors</Error>
        )}
        <h5>This form is intended for {companyForm !== true ? 'AnswerNet' : 'Ion Solar Pros'} only</h5>
      </form>
    </InviteIntakeForm>
    </>
  )

}

export default AgentIntakeForm;

const InviteIntakeForm = styled.div`
  z-index: 1;
  min-height: 400px;
  display: flex;
  align-items: flex-start;
  padding: 40px 20px 200px;
  justify-content: center;
  background-color: #efefef;
  min-height: 100vh;
  .hidden {
    display: none;
  }
  & > form {
    width: 720px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    max-width: calc(100% - 40px);
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    overflow: hidden;

        & >  div {
          display: flex;
          flex-flow: column;
          flex-basis: 100%;
          margin: 8px 0;
          max-width: 100%;
          &.form-field-phone {
            input {
              max-width: 145px;
            }
            margin-bottom: 16px;
          }

          &.form-field-appointment {
            .form-field-appointment-picker-wrapper {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-left: -5px;
              margin-right: -5px;
              & > div {
                margin: 5px;
              }
            }
          }
        }
        & > .city-state-zip-wrapper {
          @media screen and (max-width: 599px) {
            & > div {
              display: flex;
              flex-direction: column;
              margin: 8px 0;
            }
          }
          @media screen and (min-width: 600px) {
            margin-left: -5px;
            margin-right: -5px;
            max-width: calc(100% + 10px);
            flex-direction: row;
            & > div {
              margin: 0px 5px;
            }
            .form-field-city {
              flex: 7;
              input {
                width: calc(100% - 28px);
              }
            }
            .form-field-state {
              flex: 8;
              input {
                width: calc(100% - 28px);
              }
            }
            .form-field-zip {
              flex: 5;
              min-width: 50px;
              input {
                width: calc(100% - 28px);
              }
            }
          }
        }

    select {
      flex-basis: 100%;
      padding: 16px 12px;
      border-radius: 4px;
      border: 1px solid rgb(209,209,209);
    }
    input {
      flex-basis: 100%;
      padding: 14px;
      border-radius: 4px;
      border: none;
      background-color: #f8f8f8;
      &[type="submit"] {
        background: rgb(251, 149, 16);
        font-weight: 600;
        color: #ffffff;
        font-size: 1.5em;
        margin-top: 8px;
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }

    }
    .form-response {
      margin: 8px 15px 0;
      font-weight: 600;
      &.form-response-error {
        color: red;
      }
      &.form-response-success {
        color: green;
      }
    }
    label {
      color: black;
      font-weight: 400;
      display: block;
    }
    button {
      &:focus, &:active {
        outline: none;
        border: none;
      }
    }
    h5 {
      text-align: right;
    }
  }
`;

const CallTypeButtonWrapper = styled.div`
  width: calc(100% + 16px);
  max-width: calc(100% + 16px) !important;
  display: flex;
  flex-flow: row !important;
  justfy-content: center;
  align-items: stretch;
  margin-right: -8px !important;
  margin-left: -8px !important;
  margin-bottom: 60px !important;
`;
const CallTypeButton = styled.div`
  flex-grow: 1;
  border: none;
  padding: 0;
  margin: 8px;
  flex-basis: 33.3%;
  background: #fafafa;
  border-radius: 8px;

  &.selected {
    background: #0078C1;
    label {
      font-weight: 600;
      color: white;
    }
  }
  input {
    display: none;
  }
  label {
    padding: 30px 8px;
    font-weight: 600;
    font-size: 18px;
    color: #999999;
    text-transform: capitalize;
    text-align: center;
  }
`;

const Error = styled.p`
  color: red;
  margin: 0;
  font-weight: 900;
  font-size: 12px;
`;

const Separator = styled.div`
  height: 3px;
  border: 1px solid #eeeeee;
  margin: 20px 0 !important;

`;

const TermsLink = styled.a`
  color: #fb9510;
  text-decoration: none !important;
`;

const TermsWrapper = styled.div`
  flex-flow: row !important;
  align-items: center;
`;

const TermsCheckbox = styled.input`
  flex: 0;
  flex-basis: 0 !important;
`;
const TermsLabel = styled.label`
  margin-left: 8px;
`;

const ScriptText = styled.p`
  &.customer {
    color: #0078C1;
    span, svg {
      color: #0078C1;
    }
  }
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  svg {
    flex: 0;
    width: 100%;
    fontSize: 22;
    margin-right: 8px;
  }
  span {
    font-weight: 900;
    min-width: 115px;
  }
  span, svg {
    color: #fb9510;
  }
`;
const ScriptNote = styled.em`
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  width: 100%;
`;

const BusyOverlay = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.8);
`;
